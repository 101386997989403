<div class="col-lg-12 progress-wrapper">
	<div class="progress">
		<div
			aria-valuemax="100"
			aria-valuemin="0"
			aria-valuenow="35"
			class="progress-bar indeterminate"
			role="progressbar"
			style="width: 35%"></div>
	</div>
</div>
