import {inject, NgModule} from '@angular/core';
import {CanMatchFn, RouterModule, Routes} from '@angular/router';

import {AuthService} from './services/auth.service';

const canLoad: CanMatchFn = () => {
	const service = inject(AuthService);
	const canActivate = Boolean(service.user?.token) ?? false;

	if (!canActivate) {
		service.logOut();
	}

	return canActivate;
};

const routes: Routes = [
	{path: '', redirectTo: 'cabinet', pathMatch: 'full'},
	{
		path: 'auth',
		loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
	},
	{
		path: 'cabinet',
		loadChildren: () => import('./modules/cabinet/cabinet.module').then((m) => m.CabinetModule),
		canMatch: [canLoad],
	},
	{
		path: 'qr',
		loadComponent: () => import('./modules/qr/qr.component').then((m) => m.QrComponent),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
