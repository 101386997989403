import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {catchError, throwError} from 'rxjs';

import {Notyf} from 'notyf';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler) {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				const notyf = new Notyf({
					position: {
						x: 'right',
						y: 'top',
					},
				});

				if (typeof error.error !== 'string') {
					const errors = Object.values(error.error) as string[] | string[][];

					errors.forEach((err_object) => {
						if (Array.isArray(err_object)) {
							err_object.forEach((err) => {
								notyf.error(err);
							});
						} else {
							notyf.error(err_object);
						}
					});
				} else {
					notyf.error(error.statusText);
				}
				return throwError(() => error);
			}),
		);
	}
}
