import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {catchError, throwError} from 'rxjs';

import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler) {
		return next.handle(this.addToken(request)).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401) this.authService.logOut();

				return throwError(() => error);
			}),
		);
	}

	private addToken(request: HttpRequest<unknown>) {
		const token = this.authService.user?.token;

		if (!token) return request;

		return request.clone({
			headers: request.headers.set('Authorization', 'Token ' + token),
		});
	}
}
