import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class GlobalStorageService {
	hideBranches = new BehaviorSubject(false);
}
