import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {finalize} from 'rxjs/operators';

import {ProgressIndicatorService} from '../services/progress-indicator.service';

@Injectable()
export class ProgressLoaderInterceptor implements HttpInterceptor {
	constructor(private requestCount: ProgressIndicatorService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler) {
		this.requestCount.increment();

		return next.handle(request).pipe(
			finalize(() => {
				this.requestCount.decrement();
			}),
		);
	}
}
