import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AuthInterceptor} from './interceptors/auth.interceptor';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {ProgressLoaderInterceptor} from './interceptors/progress-loader.interceptor';

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';

registerLocaleData(localeRu);

@NgModule({
	declarations: [AppComponent, ProgressBarComponent],
	imports: [BrowserModule, BrowserAnimationsModule, HttpClientModule, AppRoutingModule],
	providers: [
		{provide: LOCALE_ID, useValue: 'ru'},
		{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ProgressLoaderInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
	exports: [],
})
export class AppModule {}
