import {User} from '../models/user';

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

const storage = sessionStorage;

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	user?: User;

	constructor(private router: Router) {
		const user = storage.getItem('mazzami_user');
		if (user) this.user = JSON.parse(user);
	}

	logIn(user: User, redirectTo: string[] = ['/cabinet']) {
		this.user = user;
		storage.setItem('mazzami_user', JSON.stringify(user));
		void this.router.navigate(redirectTo);
	}

	logOut() {
		this.user = undefined;
		storage.removeItem('mazzami_user');
		void this.router.navigate(['/auth']);
	}
}
