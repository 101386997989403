import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, ActivationEnd, NavigationEnd, NavigationStart, Router} from '@angular/router';

import {delay, Observable} from 'rxjs';

import {GlobalStorageService} from './services/global-storage.service';
import {ProgressIndicatorService} from './services/progress-indicator.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
	showIndicator$?: Observable<number>;

	constructor(
		private router: Router,
		private globalStorageService: GlobalStorageService,
		private progressIndicator: ProgressIndicatorService,
	) {}

	ngOnInit() {
		this.showIndicator$ = this.progressIndicator.state$.pipe(delay(0));

		let activatedRouteSnapshot: ActivatedRouteSnapshot | undefined;

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				activatedRouteSnapshot = undefined;
			}

			if (!activatedRouteSnapshot && event instanceof ActivationEnd) {
				activatedRouteSnapshot = event.snapshot;
			}

			if (activatedRouteSnapshot && event instanceof NavigationEnd) {
				this.globalStorageService.hideBranches.next(activatedRouteSnapshot.data['hideBranches'] ?? false);
			}
		});
	}
}
